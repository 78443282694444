/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*------------------------------------------------------------------
[Dark-stylesheet Stylesheet]

Project        :   Valex - HTML5 Bootstrap Admin Template
Version        :   V.1
Create Date    :   05/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
/* ------ Table Of Contents
	** FONTS IMPORT
	** BOOTSTRAP FRAMEWORK
	** COMPONENT COLS & ROWS
	** TABLE STYLES
	** FROM ELEMENTS
	** BUTTON STYLES
	** DROPDOWN STYLES
	** INPUT GROUP
	** CUSTOM CONTROL
	** NAVIGATION
	** CARD STYLES
	** ACCORDION
	** BREADCRUMB
	** PAGINATION
	** BADGES
	** JUMBOTRON
	** ALERTS
	** LIST GROUP
	** TOAST & MODAL
	** TOOLTIP & POPOVER
	** CAROUSEL
	** DEFAULT ELEMENTS
	** DATE PICKER
	** WIZARD ELEMENTS
	** JQMAP
	** RANGE SLIDER
	** PERFECT SCROLLBAR
	** SELECT2
	** SPECTRUM
	** DATE PICKER
	** CUSTOM STYLES
	** BACKGROUNDS
	** BORDERS
	** HEIGHT
	** MARGIN
	** MARGIN & PADDING
	** POSITION & VALUES
	** TEXT SIZES & FONTS
	** CUSTOM CSS


/*---FONTS IMPORT --- */
body.dark-theme {
  color: #fff;
  background: #141b2d;
}

.dark-theme .card {
  background: #1f2940 !important;
  border: 1px solid #1f2940 !important;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}
.dark-theme .main-header {
  background: #1f2940;
  border-bottom: 1px solid rgba(222, 228, 236, 0.1);
  box-shadow: 0 0 10px rgba(20, 28, 43, 0.8);
}
.dark-theme .side-header {
  border-right: 0;
}
.dark-theme .main-header-message > a, .dark-theme .main-header-notification > a, .dark-theme .nav-item.full-screen > a, .dark-theme .card-title {
  color: #fff;
}
.dark-theme .card-dashboard-audience-metrics .card-header, .dark-theme .card-dashboard-audience-metrics .card-body {
  background-color: #1f2940;
}
.dark-theme .card-header {
  background-color: #1f2940;
  border-bottom: 0;
}
.dark-theme .border-bottom {
  border-bottom: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .border-top {
  border-top: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .border-right {
  border-right: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .border-left {
  border-left: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .border {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .table tbody tr, .dark-theme .table-bordered thead th {
  background-color: #1f2940;
}

.table-bordered thead td {
  background-color: #1f2940;
}

.dark-theme .table {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .table-bordered {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}
.dark-theme .table-bordered th, .dark-theme .table-bordered td {
  border: 1px solid rgba(234, 236, 241, 0.1);
}
.dark-theme .card-dashboard-audience-metrics .card-body h4 {
  color: #fcfcfd;
}
.dark-theme .progress {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .card-dashboard-audience-metrics .flot-chart .flot-x-axis > div span:last-child {
  color: #b9c6de;
}
.dark-theme .main-footer {
  background-color: #1f2940;
  border-top: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .sidebar.sidebar-right {
  box-shadow: 5px 7px 26px -5px #030f2d !important;
}
.dark-theme .sidebar .tabs-menu ul {
  border-bottom: 1px solid rgba(235, 234, 241, 0.1);
}
.dark-theme .chat .contacts li {
  border-bottom: 1px solid rgba(227, 227, 247, 0.1);
}
.dark-theme .form-control {
  color: #fff;
  background-color: #232e48;
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .list-group-item {
  background-color: #1f2940;
  border: 1px solid rgba(231, 235, 243, 0.1);
}
.dark-theme .main-header-center .form-control {
  border-color: rgba(255, 255, 255, 0.1) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.dark-theme .main-header form[role=search].active input {
  background: #1f2940;
}
.dark-theme .main-header form[role=search] button[type=reset] {
  background: transparent;
}
.dark-theme .main-header .input-group-btn .btn:hover i {
  color: #fff;
}
.dark-theme .main-header-notification .dropdown-menu {
  background-color: #1f2940;
}
.dark-theme .notification-label {
  color: #fff;
}
.dark-theme .main-notification-list a:hover, .dark-theme .main-message-list a:hover {
  background: rgba(255, 255, 255, 0.05);
}
.dark-theme .nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 1px #041138;
}
.dark-theme .notification-subtext {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .main-header-message .dropdown-footer, .dark-theme .main-header-notification .dropdown-footer {
  border-top: 1px solid rgba(220, 225, 239, 0.1);
  background: #262e44;
}
.dark-theme .dropdown-menu {
  color: #fff;
  background-color: #1f2940;
  border: 1px solid rgba(138, 153, 191, 0.125);
}
.dark-theme .main-message-list a .name {
  color: #fff;
}
.dark-theme .main-message-list a .time, .dark-theme .main-message-list a .desc {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .task-line a {
  color: #fff;
}
.dark-theme .latest-tasks .nav-tabs .nav-link {
  color: #7987a1;
  background: transparent;
}
.dark-theme .chips p {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .chip {
  color: #fff;
  background-color: rgba(245, 246, 251, 0.1);
}

@media (min-width: 1245px) {
  .dark-theme .main-header-center .form-control {
    border-color: rgba(255, 255, 255, 0.1) !important;
    background-color: #29344e !important;
  }
}
.chip:hover {
  background: #8485fb;
  color: #fff;
}

.dark-theme .latest-tasks .check-box .ckbox span:before {
  border: 1px solid rgba(217, 226, 255, 0.1);
}
.dark-theme .table th, .dark-theme .table td {
  border-top: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme #global-loader {
  background: #1a233a;
}
.dark-theme .app-sidebar {
  background: #1f2940;
  border-top: 0;
  box-shadow: 0 0 10px rgba(20, 28, 43, 0.8);
  border-right: 1px solid rgba(222, 228, 236, 0.1);
}
.dark-theme .main-sidebar-header {
  background: #1f2940;
  background: #1f2940;
}
.dark-theme .side-menu__label, .dark-theme .side-menu .side-menu__icon {
  color: #a0aac3;
  fill: #a0aac3;
}
.dark-theme .app-sidebar__user .user-info h4 {
  color: #fff !important;
}
.dark-theme .side-menu h3 {
  color: rgba(255, 255, 255, 0.4) !important;
}
.dark-theme .main-sidebar-header {
  border-bottom: 1px solid rgba(222, 228, 236, 0.1);
  border-right: 1px solid rgba(222, 228, 236, 0.1);
}
.dark-theme .main-sidebar-loggedin .media-body h6 {
  color: #fff;
}
.dark-theme .app-sidebar .slide.active .side-menu__item {
  background: transparent;
}
.dark-theme .slide.is-expanded {
  background: transparent;
}
.dark-theme .slide.is-expanded a {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .side-menu__item {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .app-sidebar__user .user-pro-body img {
  border: 2px solid #0caadf;
  background: rgba(109, 110, 243, 0.2);
}
.dark-theme .slide.is-expanded:before {
  background: rgba(227, 231, 237, 0.1);
}
.dark-theme .slide-menu .slide-item:before {
  border-color: #6d7582;
}
.dark-theme .main-logo, .dark-theme .logo-1, .dark-theme .desktop-logo.active.logo-light {
  display: none;
}
.dark-theme .main-logo.dark-theme {
  display: block;
}
.dark-theme .desktop-logo {
  margin: 0 auto;
}
.dark-theme .open-toggle svg g, .dark-theme .close-toggle svg g {
  fill: #fff;
}
.dark-theme .angle {
  color: rgba(255, 255, 255, 0.2) !important;
}
.dark-theme .main-header-center .btn:hover, .dark-theme .main-header-center .sp-container button:hover {
  color: #fff;
}
.dark-theme .sp-container .main-header-center button:hover {
  color: #fff;
}
.dark-theme .main-header-center .btn:focus, .dark-theme .main-header-center .sp-container button:focus {
  color: #fff;
}
.dark-theme .sp-container .main-header-center button:focus, .dark-theme .main-header .input-group-btn .btn i {
  color: #fff;
}
.dark-theme .main-mail-item {
  border-top: 1px solid rgba(227, 231, 237, 0.1);
  border-bottom: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme .main-mail-item.unread {
  background-color: #1f2940;
}
.dark-theme .main-content-title {
  color: #fff;
}
.dark-theme .main-mail-options {
  border: 1px solid rgba(226, 232, 245, 0.1);
  border-bottom: 0;
}
.dark-theme .main-mail-list {
  border: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme .main-mail-item {
  background-color: #1f2940;
}
.dark-theme .main-mail-subject strong {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .ckbox span:before {
  background-color: rgba(227, 231, 237, 0.1);
  border: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme .main-mail-star {
  color: rgba(255, 255, 255, 0.1);
}
.dark-theme .main-nav-column .nav-link {
  color: #7987a1;
}
.dark-theme .main-nav-column .nav-link:hover, .dark-theme .main-nav-column .nav-link:focus {
  color: #fff;
}
.dark-theme .btn-light {
  color: #fff;
  background-color: rgba(226, 232, 245, 0.1);
  border-color: rgba(189, 198, 214, 0.2);
}
.dark-theme .main-nav-column .nav-link:hover i:not([class*=" tx-"]), .dark-theme .main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
  color: #fff;
}
.dark-theme .main-nav-column .nav-link + .nav-link {
  border-top: 1px dotted rgba(180, 189, 206, 0.3);
}
.dark-theme .nav-search .input-group-text {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 0;
}
.dark-theme .main-nav-column .nav-link.active {
  color: #0162e8 !important;
}
.dark-theme .main-nav-column .nav-link.active:hover, .dark-theme .main-nav-column .nav-link.active:focus {
  color: #0162e8 !important;
}
.dark-theme .main-mail-header .btn-group .btn {
  border-color: #555c6e;
  background-color: #555c6e;
}

/*----- Left-Sidemenu -----*/
@media (max-width: 991px) and (min-width: 574px) {
  .dark-theme .responsive-logo .dark-logo-2, .dark-theme .responsive-logo .logo-2 {
    dispLay: none;
  }
  .dark-theme .responsive-logo .dark-logo-1 {
    height: 2rem;
  }
}
@media (max-width: 574px) {
  .dark-theme .responsive-logo .logo-2 {
    dispLay: none;
  }
  .dark-theme .responsive-logo .dark-logo-2 {
    dispLay: block;
    height: 2.5rem;
  }
  .dark-theme .responsive-logo .dark-logo-1 {
    dispLay: none;
  }
}
/*----- Left-Sidemenu -----*/
.main-mail-header .btn-group .sp-container button, .sp-container .main-mail-header .btn-group button {
  border-color: #555c6e;
  background-color: #555c6e;
}

.dark-theme .main-mail-header .btn-group .btn.disabled, .dark-theme .main-mail-header .btn-group .sp-container button.disabled {
  background-color: #555c6e;
  color: #e2e8f5;
  border-color: #555c6e;
}
.dark-theme .sp-container .main-mail-header .btn-group button.disabled {
  background-color: #555c6e;
  color: #e2e8f5;
  border-color: #555c6e;
}
.dark-theme .main-mail-header .btn-group .btn:hover, .dark-theme .main-mail-header .btn-group .sp-container button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}
.dark-theme .sp-container .main-mail-header .btn-group button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}
.dark-theme .main-mail-header .btn-group .btn:focus, .dark-theme .main-mail-header .btn-group .sp-container button:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}
.dark-theme .sp-container .main-mail-header .btn-group button:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}
.dark-theme .card-header, .dark-theme .card-footer {
  position: relative;
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme hr {
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .main-content-label, .dark-theme .card-table-two .card-title, .dark-theme .card-dashboard-eight .card-title {
  color: #fff;
}
.dark-theme .form-label {
  color: #cfdaec;
}
.dark-theme .select2-container--default .select2-selection--single {
  background-color: #232e48;
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #cfdaec;
}
.dark-theme .select2-dropdown {
  background-color: #1f2940;
  border-color: rgba(255, 255, 255, 0.05);
}
.dark-theme .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme .select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: rgba(226, 232, 245, 0.2);
  background: #1f2940;
  color: #fff;
}
.dark-theme .main-nav-line-chat {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1) !important;
}
.dark-theme .main-nav-line .nav-link {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .main-chat-msg-name h6 {
  color: #fff;
}
.dark-theme .main-chat-header {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
  box-shadow: 2px 3px 14px #1a233a;
}
.dark-theme .main-nav-line-chat .nav-link.active {
  color: #0162e8 !important;
  background: transparent;
}
.dark-theme .main-chat-contacts-more {
  background-color: #0162e8;
}
.dark-theme .main-chat-list .media {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-chat-list .media.new {
  background-color: #1f2940;
}
.dark-theme .main-chat-list .media.new .media-body p {
  color: #a9b2c7;
}
.dark-theme .main-chat-list .media.new .media-contact-name span:first-child {
  color: #f3f6fb;
}
.dark-theme .main-chat-list .media-contact-name span:first-child {
  color: #fff !important;
}
.dark-theme .main-chat-list .media.selected {
  background-color: rgba(243, 246, 251, 0.1);
}
.dark-theme .main-chat-contacts-wrapper {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-chat-list .media:hover, .dark-theme .main-chat-list .media:focus {
  background-color: rgba(252, 252, 252, 0.05);
}
.dark-theme .main-chat-list .media.selected .media-body p {
  color: #b7bfd2;
}
.dark-theme .main-msg-wrapper {
  background-color: rgba(226, 232, 245, 0.1);
  color: #fff;
}
.dark-theme .main-chat-footer {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
  background-color: #24304c;
  z-index: 999;
}
.dark-theme .main-chat-footer .form-control {
  background: transparent;
  border-color: transparent;
}
.dark-theme .irs-line-mid, .dark-theme .irs-line-left, .dark-theme .irs-line-right {
  background-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .irs-min, .dark-theme .irs-max {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .main-calendar .fc-header-toolbar h2 {
  color: #fff;
}
.dark-theme .ui-datepicker {
  background-color: #1f2940;
  box-shadow: 0 0 24px rgba(20, 28, 43, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .ui-datepicker .ui-datepicker-calendar td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #1f2940;
}
.dark-theme .ui-datepicker .ui-datepicker-calendar td span, .dark-theme .ui-datepicker .ui-datepicker-calendar td a {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .ui-datepicker .ui-datepicker-title, .dark-theme .ui-datepicker .ui-datepicker-calendar th {
  color: #fff;
}
.dark-theme .main-datepicker .ui-datepicker .ui-datepicker-calendar th {
  color: #fafcff;
}
.dark-theme .card--calendar .ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid rgba(227, 227, 227, 0.1);
}
.dark-theme .ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: #0162e8;
  color: #fff;
}
.dark-theme .main-calendar .fc-view > table {
  background-color: #1f2940;
}
.dark-theme .main-calendar .fc-head-container .fc-day-header {
  color: #fff;
}
.dark-theme .main-calendar .fc-view .fc-day-number {
  color: #fff;
}
.dark-theme .main-calendar .fc-view .fc-other-month {
  background-color: rgba(40, 92, 247, 0.07);
}
.dark-theme .main-calendar .fc-content {
  border-color: rgba(226, 232, 245, 0.1);
}

.main-calendar .fc-divider {
  border-color: rgba(226, 232, 245, 0.1);
}

.dark-theme .main-calendar .fc-list-heading td, .dark-theme .main-calendar .fc-list-view, .dark-theme .main-calendar .fc-popover, .dark-theme .main-calendar .fc-row, .dark-theme .main-calendar tbody, .dark-theme .main-calendar td {
  border-color: rgba(226, 232, 245, 0.1);
}

.main-calendar th {
  border-color: rgba(226, 232, 245, 0.1);
}

.dark-theme .main-calendar thead {
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .main-calendar .fc-view .fc-day-number:hover, .dark-theme .main-calendar .fc-view .fc-day-number:focus {
  color: #fff;
  background-color: transparent;
}
.dark-theme .main-calendar td.fc-today {
  background-color: #0162e8;
}
.dark-theme .main-calendar .fc-view > table > thead th, .dark-theme .main-calendar .fc-view > table > thead td {
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .main-calendar .fc-view > table > tbody > tr > td {
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .main-calendar .fc-header-toolbar button {
  background-color: #384361;
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-calendar .fc-header-toolbar button.fc-state-active {
  background-color: #0162e8;
}
.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item, .dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
  background-color: #333d5a;
}
.dark-theme .fc-unthemed .fc-divider, .dark-theme .fc-unthemed .fc-list-heading td, .dark-theme .fc-unthemed .fc-popover .fc-header {
  background: #384361;
}
.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc, .dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a, .dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a, .dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child, .dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
  color: #fff;
}
.dark-theme .main-contact-info-header .media-body p, .dark-theme .tx-inverse {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .contact-icon:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.dark-theme .main-contact-info-header {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-contact-info-body .media-body span {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .main-contact-info-body .media-body label {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .main-contact-info-body .media + .media::before {
  border-top: 1px dotted rgba(226, 232, 245, 0.2);
}
.dark-theme .main-contact-body span {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .main-contact-body h6 {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .main-contact-item.selected {
  border-left-color: #0162e8;
  border-top-color: rgba(226, 232, 245, 0.01) !important;
  border-bottom-color: rgba(226, 232, 245, 0.01) !important;
  background-color: #2a3754;
}
.dark-theme .main-contact-item + .main-contact-item {
  border-top-color: rgba(227, 231, 237, 0.19);
}
.dark-theme .main-contact-item + .main-contact-item::before {
  border-top: 1px solid rgba(227, 231, 237, 0.05);
}
.dark-theme .main-contact-item:hover, .dark-theme .main-contact-item:focus {
  background-color: #2a3754;
  border-top-color: rgba(227, 231, 237, 0.1);
  border-bottom-color: rgba(227, 231, 237, 0.1);
}
.dark-theme .main-contact-label::after {
  border-bottom: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme #ui_notifIt.default {
  background-color: #3e465b;
  border: 1px solid rgba(227, 231, 237, 0.19);
}
.dark-theme .notifit_confirm, .dark-theme .notifit_prompt {
  background-color: #3e465b;
}
.dark-theme .alert .close {
  color: #fff;
  opacity: 0.7;
}
.dark-theme .tree li {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .tree li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .tree ul li:last-child:before {
  background: #1f2940;
}
.dark-theme .tree ul:before {
  border-left: 1px solid rgba(227, 227, 227, 0.2);
}
.dark-theme .tree ul li:before {
  border-top: 1px solid rgba(227, 227, 227, 0.2);
}
.dark-theme .text-muted {
  color: rgba(255, 255, 255, 0.4) !important;
}
.dark-theme .main-icon-group {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .table thead th {
  border-bottom: 1px solid rgba(227, 231, 237, 0.1);
  border-top: 0 !important;
  color: #fff;
}
.dark-theme .table-hover tbody tr:hover {
  color: #fff;
  background-color: #25304a !important;
}
.dark-theme table.dataTable {
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme table.dataTable tbody td.sorting_1 {
  background-color: #1f2940;
}
.dark-theme table.dataTable thead th, .dark-theme table.dataTable thead td {
  color: #fff;
}
.dark-theme table.dataTable thead .sorting_asc, .dark-theme table.dataTable thead .sorting_desc {
  background-color: #25304a;
}
.dark-theme #example-delete.table thead th {
  border-bottom: 0;
}
.dark-theme .dataTables_wrapper .dataTables_length, .dark-theme .dataTables_wrapper .dataTables_filter, .dark-theme .dataTables_wrapper .dataTables_info, .dark-theme .dataTables_wrapper .dataTables_processing, .dark-theme .dataTables_wrapper .dataTables_paginate {
  color: #fff;
}
.dark-theme .dataTables_wrapper .dataTables_filter input {
  border: 1px solid rgba(226, 232, 245, 0.2);
}
.dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: transparent;
}
.dark-theme .page-link {
  color: #fff;
  background-color: #27334e;
}
.dark-theme .dataTables_wrapper .dataTables_paginate .page-item.disabled .page-link {
  background-color: #212b42;
  color: #45516b;
}
.dark-theme select option {
  background: #1f2940;
}
.dark-theme table.dataTable tbody tr.selected {
  background: #25304a;
}
.dark-theme .example {
  padding: 1rem;
  border: 1px solid rgba(225, 230, 241, 0.1);
}
.dark-theme #basic-alert .alert .close, .dark-theme #dismiss-alerts .alert .close {
  color: #000;
  opacity: 0.3;
}
.dark-theme #icon-dismissalerts .alert .close {
  color: #000;
  opacity: 0.3;
}
.dark-theme #icon-dismissalerts .alert-default.alert-dismissible .close {
  color: #fff;
}
.dark-theme .main-table-reference > thead > tr > th, .dark-theme .main-table-reference > thead > tr > td {
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-table-reference > tbody > tr > th, .dark-theme .main-table-reference > tbody > tr > td {
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme pre {
  color: #dfe1ef;
  background-color: #141b2d;
  text-shadow: 0 1px #242266;
}
.dark-theme code.language-markup {
  background: transparent;
  border: transparent;
}
.dark-theme .token {
  color: #dc2a2a;
}
.dark-theme .token.selector, .dark-theme .token.attr-name, .dark-theme .token.string, .dark-theme .token.char, .dark-theme .token.builtin, .dark-theme .token.inserted {
  color: #4e9cf1;
}
.dark-theme .token.atrule, .dark-theme .token.attr-value, .dark-theme .token.keyword {
  color: #e40dae;
}
.dark-theme .token.operator, .dark-theme .token.entity, .dark-theme .token.url {
  color: #ecc494;
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .language-css .token.string, .dark-theme.style .token.string {
  color: #ecc494;
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .highlight {
  border: 1px solid #141b2d !important;
  background: #191f3a;
}
.dark-theme .clipboard-icon {
  background: #141b2d;
  border: 1px solid rgba(225, 230, 241, 0.1);
}
.dark-theme .main-table-reference {
  background: transparent;
}
.dark-theme .main-table-reference > thead > tr > th, .dark-theme .main-table-reference > thead > tr > td {
  background: #293652 !important;
  border: 1px solid rgba(225, 230, 241, 0.1) !important;
}
.dark-theme .breadcrumb-style1 .breadcrumb-item a, .dark-theme .breadcrumb-style2 .breadcrumb-item a, .dark-theme .breadcrumb-style3 .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .dropdown-item {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .dropdown-item:hover, .dark-theme .dropdown-item:focus {
  background: #233152;
}
.dark-theme .dropdown-divider {
  border-top: 1px solid rgba(227, 231, 237, 0.16);
}
.dark-theme .img-thumbnail {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .img-thumbnail p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
.dark-theme .bd {
  border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .bg-light {
  background-color: #28344e !important;
}
.dark-theme .main-nav .nav-link:hover, .dark-theme .main-nav .nav-link:focus {
  color: #fff;
}
.dark-theme .nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .nav-pills .nav-link:hover, .dark-theme .nav-pills .nav-link:focus {
  color: #fff;
}
.dark-theme .nav-pills .nav-link.active {
  color: #fff !important;
}
.dark-theme .main-nav .nav-link {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .bg-gray-300 {
  background-color: #323b54;
}
.dark-theme #tab .bg-gray-300 {
  background-color: #37415f;
  border-bottom: 1px solid #404563;
}
.dark-theme .nav-tabs .nav-link {
  color: white;
}
.dark-theme .nav-tabs .nav-link.active, .dark-theme .nav-tabs .nav-link:hover, .dark-theme .nav-tabs .nav-link:focus {
  background-color: #0162e8;
  color: #fff;
}
.dark-theme .popover-static-demo {
  background-color: #141b2d;
}
.dark-theme .popover {
  background-color: #373e52;
  border: 1px solid #333c52;
}
.dark-theme .popover-body {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .popover-header {
  color: rgba(255, 255, 255, 0.7);
  background-color: #373e52;
  border-color: rgba(133, 140, 152, 0.2);
}
.dark-theme .bs-popover-top > .arrow::before, .dark-theme .bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: #4e566d;
}
.dark-theme .bs-popover-top > .arrow::after, .dark-theme .bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #373e52;
}
.dark-theme .bs-popover-bottom > .arrow::after, .dark-theme .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #373e52;
}
.dark-theme .bs-popover-bottom > .arrow::before, .dark-theme .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #4e566d;
}
.dark-theme .bs-popover-left > .arrow::after, .dark-theme .bs-popover-auto[x-placement^=left] > .arrow::after, .dark-theme .bs-popover-left > .arrow::after, .dark-theme .bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #373e52;
}
.dark-theme .bs-popover-right > .arrow::after, .dark-theme .bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #373e52;
}
.dark-theme .bs-popover-right > .arrow::before, .dark-theme .bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: #4e566d;
}
.dark-theme .bs-popover-left > .arrow::before, .dark-theme .bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: #4e566d;
}
.dark-theme .bg-gray-200 {
  background-color: #141b2d;
}
.dark-theme .media-body {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .bg-gray-100 {
  background-color: #141b2d;
}
.dark-theme .tooltip-static-demo {
  background-color: #141b2d;
}
.dark-theme .toast-header {
  border-bottom-color: rgba(205, 212, 224, 0.2);
}
.dark-theme .toast {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(84, 90, 109, 0.7);
}
.dark-theme .toast-header {
  color: rgba(255, 255, 255, 0.58);
  background: #141b2d;
}
.dark-theme .bootstrap-tagsinput {
  background-color: #141b2d;
}
.dark-theme .bootstrap-tagsinput .badge {
  margin: 11px 0 12px 10px;
}
.dark-theme .tag {
  color: #fff;
  background-color: rgba(239, 239, 245, 0.1);
}
.dark-theme .accordion .card-header a {
  color: #fff;
  background-color: #232f4c;
}
.dark-theme .accordion .card-body {
  background-color: #232f4c;
}
.dark-theme .accordion .card-header a.collapsed:hover, .dark-theme .accordion .card-header a.collapsed:focus {
  color: #fff;
  background-color: #0162e8;
}
.dark-theme .modal-content {
  background-color: #1f2940;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.dark-theme .modal-header {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .modal-title {
  color: #fff;
}
.dark-theme .modal-footer {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .close {
  color: #f7f6f6;
  text-shadow: 0 1px 0 #18213c;
}
.dark-theme .modal-content-demo .modal-body h6 {
  color: #fff;
}
.dark-theme .vtimeline .timeline-wrapper .timeline-panel {
  background: #141b2d;
  box-shadow: 0 8px 16px 0 rgba(47, 53, 84, 0.24);
}
.dark-theme .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
  border-right: 14px solid #141b2d;
  border-left: 0 solid #141b2d;
}
.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
  border-left: 14px solid #141b2d;
  border-right: 0 solid #141b2d;
}
.dark-theme .vtimeline:before {
  background-color: #141b2d;
}
.dark-theme .timeline-body {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .sweet-alert {
  background-color: #1f2940;
}
.dark-theme .sweet-alert h2 {
  color: #fff;
}
.dark-theme .btn-outline-light {
  border-color: rgba(151, 163, 185, 0.2);
  color: #97a3b9;
}
.dark-theme .btn-outline-light:hover, .dark-theme .btn-outline-light:focus {
  background-color: #3d4765 !important;
  border: 1px solid #455177 !important;
  box-shadow: none !important;
  color: #fff !important;
}
.dark-theme .dropdown .fe-more-vertical {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .main-content-body-profile .nav {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .card-body + .card-body {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .rating-stars input {
  color: #fff;
  background-color: #141b2d;
  border: 1px solid rgba(234, 237, 241, 0.1);
}
.dark-theme .rating-stars .rating-stars-container .rating-star {
  color: #2e3954;
}
.dark-theme .rating-stars .rating-stars-container .rating-star.is--active, .dark-theme .rating-stars .rating-stars-container .rating-star.is--hover {
  color: #f1c40f !important;
}
.dark-theme .br-theme-bars-horizontal .br-widget a.br-active, .dark-theme .br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: #0162e8 !important;
}
.dark-theme .br-theme-bars-pill .br-widget a.br-active, .dark-theme .br-theme-bars-pill .br-widget a.br-selected {
  background-color: #0162e8 !important;
  color: white;
}
.dark-theme .br-theme-bars-1to10 .br-widget a, .dark-theme .br-theme-bars-movie .br-widget a, .dark-theme .br-theme-bars-horizontal .br-widget a {
  background-color: #2e3954;
}
.dark-theme .br-theme-bars-1to10 .br-widget a.br-active, .dark-theme .br-theme-bars-1to10 .br-widget a.br-selected {
  background-color: #0162e8 !important;
}
.dark-theme .br-theme-bars-movie .br-widget a.br-active, .dark-theme .br-theme-bars-movie .br-widget a.br-selected {
  background-color: #0162e8 !important;
}
.dark-theme .br-theme-bars-square .br-widget a {
  border: 2px solid #4f576f;
  background-color: #141b2d;
  color: #8694a5;
}
.dark-theme .br-theme-bars-square .br-widget a.br-active, .dark-theme .br-theme-bars-square .br-widget a.br-selected {
  border: 2px solid #0162e8;
  color: #0162e8;
}
.dark-theme .br-theme-bars-pill .br-widget a {
  background-color: #141b2d;
}
.dark-theme .custom-file-label {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.07);
}
.dark-theme .custom-file-label::after {
  color: #fff;
  background-color: #0162e8;
  border: 1px solid #0162e8;
}
.dark-theme .input-group-text {
  color: rgba(255, 255, 255, 0.5);
  background-color: #273450;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .sp-replacer {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #2d3653;
}
.dark-theme .sp-replacer:hover, .dark-theme .sp-replacer:focus {
  border-color: rgba(255, 255, 255, 0.2);
}
.dark-theme .sp-container {
  background-color: #1f2940;
  border-color: rgba(226, 232, 245, 0.2);
}
.dark-theme .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
}
.dark-theme .select2-container--default .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.dark-theme .SumoSelect > .CaptionCont {
  border: 1px solid rgba(225, 230, 241, 0.1);
  color: #99a6b7;
  background-color: #242f4a;
}
.dark-theme .SumoSelect > .optWrapper {
  background: #1f2940;
  border: 1px solid rgba(234, 234, 236, 0.15);
  box-shadow: 0 2px 17px 2px #070456;
}
.dark-theme .SumoSelect .select-all {
  border-bottom: 1px solid rgba(234, 234, 236, 0.15);
  background-color: #1f2940;
}
.dark-theme .SumoSelect > .optWrapper > .options li.opt {
  border-bottom: 1px solid rgba(234, 234, 236, 0.15);
}
.dark-theme .SumoSelect > .optWrapper > .MultiControls {
  border-top: 1px solid rgba(234, 234, 236, 0.15);
  background-color: #1f2940;
}
.dark-theme .SumoSelect.open > .optWrapper {
  box-shadow: 0 2px 17px 2px #1c2140;
}
.dark-theme .SumoSelect > .optWrapper > .options li.opt:hover {
  background-color: rgba(244, 245, 245, 0.1);
}
.dark-theme .SumoSelect > .optWrapper.multiple > .options li.opt span i {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .SumoSelect .select-all > span i {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .dropify-wrapper {
  background-color: #242f4a;
  border: 1px solid rgba(239, 242, 247, 0.07);
  color: #fff;
}
.dark-theme .dropify-wrapper .dropify-preview {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .dropify-wrapper:hover {
  background-image: linear-gradient(-45deg, rgba(250, 251, 254, 0.1) 25%, transparent 25%, transparent 50%, rgba(250, 251, 254, 0.1) 50%, rgba(250, 251, 254, 0.1) 75%, transparent 75%, transparent);
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone {
  border: 2px dashed #5e6882;
  background-color: #242f4a;
  background-image: url(../plugins/fancyuploder/fancy_upload-dark.png);
}
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:hover, .dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:focus, .dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:active {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .main-form-group {
  border: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .main-form-group .form-control {
  padding: 0 15px;
}
.dark-theme .parsley-style-1 .parsley-input.parsley-error .form-control, .dark-theme .parsley-style-1 .parsley-checkbox.parsley-error, .dark-theme .parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
  background-color: #141b2d;
}
.dark-theme .wizard {
  border: 1px solid rgba(227, 231, 237, 0.1);
  background-color: #141b2d;
}
.dark-theme .wizard > .steps a .number, .dark-theme .wizard > .steps a:hover .number, .dark-theme .wizard > .steps a:active .number {
  background-color: #30355d;
}
.dark-theme .wizard > .content {
  border-top: 1px solid rgba(227, 231, 237, 0.1);
  border-bottom: 1px solid rgba(227, 231, 237, 0.1);
}
.dark-theme .wizard > .content > .title {
  color: #fff;
}
.dark-theme #wizard3.wizard.vertical > .content {
  border-top: 0;
  border-bottom: 0;
}
.dark-theme .ql-scrolling-demo {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .ql-scrolling-demo .ql-container .ql-editor {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .ql-snow .ql-picker-label {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background-color: #141b2d;
}
.dark-theme .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.8);
}
.dark-theme .ql-snow .ql-editor, .dark-theme .ql-snow.ql-toolbar button {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .ql-snow.ql-toolbar {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .ql-snow.ql-container {
  border-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .ql-snow .ql-picker-options {
  background-color: #1f2940;
}
.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #545b6d;
}
.dark-theme #modalQuill .modal-header {
  border-bottom: 0;
}
.dark-theme .main-profile-work-list .media-body h6 {
  color: #fff;
}
.dark-theme .main-profile-work-list .media-body p {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .main-profile-contact-list .media-body div {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .main-profile-contact-list .media-body span {
  color: #fff;
}
.dark-theme .plan-icon {
  border: 1px solid rgba(245, 246, 251, 0.1);
  background: rgba(245, 246, 251, 0.1);
}
.dark-theme .bg-success-transparent {
  background-color: rgba(77, 236, 146, 0.17) !important;
}
.dark-theme .bg-primary-transparent {
  background-color: rgba(40, 92, 247, 0.2) !important;
}
.dark-theme .bg-warning-transparent {
  background-color: rgba(245, 222, 143, 0.1) !important;
}
.dark-theme .bg-pink-transparent {
  background-color: rgba(249, 123, 184, 0.1) !important;
}
.dark-theme .bg-teal-transparent {
  background-color: rgba(166, 243, 243, 0.12) !important;
}
.dark-theme .bg-purple-transparent {
  background-color: rgba(163, 154, 249, 0.1) !important;
}
.dark-theme .bg-danger-transparent {
  background-color: rgba(243, 161, 161, 0.1) !important;
}
.dark-theme .main-profile-name, .dark-theme .main-profile-body .media-body h6 {
  color: #fbfcff;
}
.dark-theme .main-profile-social-list .media-body a {
  color: #fff;
  opacity: 0.5;
}
.dark-theme .profile-footer a {
  background: #141b2d;
  color: #fff;
}
.dark-theme .billed-from h6 {
  color: #f4f5f8;
}
.dark-theme .invoice-title {
  color: #e1e1e1;
}
.dark-theme .main-invoice-list .media-body h6 {
  color: #fff;
}
.dark-theme .main-invoice-list .selected {
  background-color: rgba(244, 245, 248, 0.1);
  border-top-color: 1px dotted rgba(226, 232, 245, 0.1);
  border-bottom-color: rgba(226, 232, 245, 0.1);
  border-left-color: #0162e8;
}
.dark-theme .main-invoice-list .media {
  border: 1px dotted rgba(226, 232, 245, 0.1);
}
.dark-theme .main-invoice-list .media + .media::before {
  border-top: 1px dotted transparent;
}
.dark-theme .main-invoice-list .media:hover, .dark-theme .main-invoice-list .media:focus {
  background-color: rgba(244, 245, 248, 0.1);
}
.dark-theme .main-invoice-list .media-body p {
  color: #9da5b5;
}
.dark-theme .main-invoice-list .media-body p span {
  color: #cbcfd8;
}
.dark-theme .table-invoice tbody > tr > th:first-child .invoice-notes p {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 767px) {
  .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right: 14px solid #141b2d !important;
    border-left: 0 solid #141b2d !important;
  }
}
@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .content, .dark-theme .wizard.vertical > .actions {
    border-left: 1px solid rgba(226, 232, 245, 0.1);
  }
}
.table-invoice tbody > tr > td:first-child .invoice-notes p {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .table-invoice tbody > tr > th:first-child, .dark-theme .table-invoice tbody > tr > td:first-child {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .billed-from p, .dark-theme .billed-to p {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .card-invoice .tx-gray-600 {
  color: #eef0f3;
}
.dark-theme .billed-to h6 {
  color: #d3d8e2;
}
.dark-theme .invoice-info-row + .invoice-info-row {
  border-top: 1px dotted rgba(226, 232, 245, 0.15);
}
.dark-theme .invoice-info-row span:first-child {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .main-invoice-list {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .card-category {
  background: rgba(239, 242, 246, 0.1);
}
.dark-theme .pricing-card .list-unstyled li {
  border-bottom: 1px solid rgba(234, 237, 241, 0.1);
}
.dark-theme .price.panel-color > .panel-body, .dark-theme .price .panel-footer {
  background-color: #26324e;
}
.dark-theme .pricing .list-unstyled li {
  border-bottom: 1px solid rgba(234, 237, 241, 0.1);
}
.dark-theme .card--events .list-group-item h6 {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .rdiobox span:before {
  background-color: #141b2d;
  border: 1px solid #4a5677;
}
.dark-theme .colorinput-color {
  border: 1px solid rgba(234, 240, 247, 0.2);
}
.dark-theme .nice-select .list {
  background-color: #1f2940;
  box-shadow: 0px 0px 15px 1px #041138;
}
.dark-theme .nice-select .option:hover, .dark-theme .nice-select .option.focus, .dark-theme .nice-select .option.selected.focus {
  background-color: rgba(237, 239, 245, 0.1);
}
.dark-theme .item-card .cardtitle a {
  color: #fefefe;
}
.dark-theme .item-card .cardprice span {
  color: #dfe5ec;
}
.dark-theme .bd-b {
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bd-r {
  border-right: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bd-t {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bd-l {
  border-left: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bd-y {
  border-top: 1px solid rgba(226, 232, 245, 0.1);
  border-bottom: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bd-x {
  border-left: 1px solid rgba(226, 232, 245, 0.1);
  border-right: 1px solid rgba(226, 232, 245, 0.1);
}
.dark-theme .bg-gray-500 {
  background-color: rgba(151, 163, 185, 0.2);
}
.dark-theme .bg-gray-400 {
  background-color: #5e687d;
}
.dark-theme .main-card-signin {
  background-color: transparent;
  border: 1px solid rgba(227, 227, 227, 0.1);
}
.dark-theme .main-signin-header h4 {
  color: #fff;
}
.dark-theme .main-signin-footer a {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .main-signup-footer a {
  color: #fff;
}
.dark-theme .main-error-wrapper h1 {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .main-error-wrapper h2 {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .main-error-wrapper h6 {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .construction .btn.btn-icon {
  border: 1px solid rgba(221, 230, 241, 0.1);
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.dark-theme .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #3b4563 !important;
}
.dark-theme .horizontalMenucontainer .desktop-logo, .dark-theme .horizontalMenucontainer .desktop-logo-dark {
  display: none;
}
.dark-theme .desktop-dark {
  display: block;
}

@media (min-width: 768px) {
  .dark-theme.sidebar-mini.sidenav-toggled .main-sidebar-header .icon-light .logo-icon {
    display: none;
    height: 2.5rem;
  }
  .dark-theme.sidebar-mini.sidenav-toggled .main-sidebar-header .icon-dark .logo-icon.dark-theme {
    display: block;
    height: 2.5rem;
  }
  .dark-theme.sidebar-mini.sidenav-toggled.sidenav-toggled1 .main-sidebar-header .logo-light .main-logo {
    display: none;
  }
}
@media (max-width: 991px) and (min-width: 568px) {
  .dark-theme .horizontalMenucontainer .desktop-dark {
    margin-left: 2.4rem;
  }
}
@media (max-width: 567px) and (min-width: 550px) {
  .dark-theme .horizontalMenucontainer .desktop-logo-dark {
    margin-left: 3rem;
  }
}
.desktop-dark {
  height: 2rem;
}

@media (max-width: 567px) {
  .dark-theme .horizontalMenucontainer .desktop-dark, .dark-theme .desktop-logo-1 {
    display: none;
  }
  .dark-theme .horizontalMenucontainer .desktop-logo-dark {
    display: block;
    height: 2.5rem;
  }
}
@media (max-width: 991px) {
  .dark-theme .animated-arrow span {
    background: #fff;
  }
  .dark-theme .animated-arrow span:before, .dark-theme .animated-arrow span:after {
    background: #fff;
  }
  .dark-theme.active .animated-arrow span {
    background-color: transparent;
  }
}
.dark-theme .sidebar {
  background: #1f2940;
}
.dark-theme .main-calendar.fc-list-empty {
  background-color: #1f2940;
  border: 1px solid #141b2d;
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar button.fc-month-button::before, .dark-theme .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before, .dark-theme .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before, .dark-theme .main-calendar .fc-header-toolbar button.fc-listWeek-button::before, .dark-theme .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
    color: #fff;
  }
}
.dark-them .breadcrumb {
  background-color: rgba(226, 232, 245, 0.1);
}
.dark-them table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid rgba(239, 239, 239, 0.1);
}
.dark-them table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child::before {
  background-color: #0162e8;
}

/*----- Horizontal-menu -----*/
.dark-theme .horizontal-main.hor-menu {
  background: #1f2940;
  border-bottom: 1px solid rgba(213, 216, 226, 0.1);
  border-top: 0;
}
.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
  color: #277aec;
  background: transparent;
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > a {
  color: #bfc8de;
}
.dark-theme .horizontalMenucontainer .main-header {
  box-shadow: none;
  border-bottom: 1px solid rgba(220, 231, 245, 0.1);
}
.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
  color: #0162e8;
  background: transparent;
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  border: 1px solid rgba(231, 234, 243, 0.1);
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .sub-menu li a:before {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
  color: rgba(255, 255, 255, 0.8);
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
  background-color: #1f2940;
  box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
  border: none;
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  color: #0162e8 !important;
}
.dark-theme .mega-menubg {
  background: #1f2940;
  box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
  border: 1px solid rgba(231, 234, 243, 0.1);
}
.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mega-menubg.hor-mega-menu h3 {
  color: #fff;
}
.dark-theme .main-profile-menu .dropdown-item + .dropdown-item {
  border-top: 1px dotted rgba(226, 234, 249, 0.2);
}
.dark-theme .rating-scroll h6 {
  color: #fff;
}
.dark-theme .latest-tasks .nav-link.active, .dark-theme .latest-tasks .nav-link:hover, .dark-theme .latest-tasks .nav-link:focus {
  background: transparent;
  color: #0162e8;
}
.dark-theme .main-calendar .fc-list-empty {
  background-color: #1f2940;
  border: 1px solid #384361;
}
.dark-theme .card.bg-info-transparent {
  background: rgba(23, 162, 184, 0.2) !important;
}
.dark-theme .form-control::-moz-placeholder {
  color: rgba(212, 218, 236, 0.3) !important;
  opacity: 1;
}
.dark-theme .form-control:-ms-input-placeholder {
  color: rgba(212, 218, 236, 0.3) !important;
  opacity: 1;
}
.dark-theme .form-control::placeholder {
  color: rgba(212, 218, 236, 0.3) !important;
  opacity: 1;
}
.dark-theme .header-icon, .dark-theme .main-header-message .nav-link i, .dark-theme .main-header-notification .nav-link i, .dark-theme .nav-item.full-screen .nav-link i, .dark-theme .sales-flot .flot-chart .flot-x-axis > div span:last-child, .dark-theme .p-text .p-name {
  color: #fff;
}
.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a.active {
  color: #0162e8;
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
  color: #696af1;
  color: #0162e8;
}
.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a.active {
  color: #0162e8 !important;
}
.dark-theme .sidebar .tab-menu-heading {
  background: #2c374e;
}
.dark-theme .sidebar .tabs-menu ul li a {
  border: 1px solid rgba(220, 231, 245, 0.1);
  background: #1f2940;
  color: #fff;
}
.dark-theme .sidebar .tabs-menu ul li .active {
  background: #0162e8;
  color: #fff;
  border: 1px solid #0162e8;
}
.dark-theme .datepicker > .datepicker_inner_container > .datepicker_calendar, .dark-theme .datepicker > .datepicker_inner_container > .datepicker_timelist {
  background-color: #1f2940;
}
.dark-theme .datepicker > .datepicker_header {
  background-color: #25304a;
  color: #fff;
}
.dark-theme .main-datetimepicker {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .main-datetimepicker > .datepicker_inner_container > .datepicker_calendar th {
  color: #fcfdff;
}
.dark-theme .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.dark-theme .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item:hover, .dark-theme .main-datetimepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item:focus {
  background-color: #323850;
}
.dark-theme .datepicker > .datepicker_inner_container > .datepicker_timelist > div.timelist_item.hover {
  color: #fff;
  background-color: #26324c;
}
.dark-theme .datetimepicker .datetimepicker-days table thead tr:last-child th {
  color: #fff;
}
.dark-theme .datetimepicker table th.dow {
  background: #1f2940;
}
.dark-theme .datetimepicker table td.old {
  color: #7a82af;
}

@media (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list {
    background: #1f2940;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > a {
    border-bottom-color: rgba(231, 234, 243, 0.1);
  }
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    border-radius: 0;
  }
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li:hover .sub-icon {
    color: #277aec;
    background: #1c2f51;
    border-bottom: 1px solid rgba(231, 234, 243, 0.1);
  }
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li:hover .side-menu__icon {
    fill: #277aec;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu li:hover > a {
    background-color: transparent;
    color: #277aec !important;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover:before {
    border-color: #eef0f7;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    color: #fff !important;
    background-color: rgba(231, 231, 231, 0.1);
  }
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li:hover a:before {
    border-color: #eef0f7 !important;
    color: #fff !important;
  }
  .dark-theme .mega-menubg li a:before {
    border-color: #8594ad;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
    display: none;
  }
  .dark-theme .mega-menubg {
    background: #1f2940 !important;
  }
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
    background: #1f2940 !important;
    color: #8291af !important;
  }
  .dark-theme .dark-logo-1 {
    display: block;
  }
}
@media (min-width: 578px) {
  .dark-theme .desktop-logo.logo-light .main-logo {
    display: none;
  }
  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon {
    display: none !important;
  }
  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon.dark-theme {
    display: none !important;
  }
  .dark-theme .desktop-logo.logo-dark .main-logo.dark-theme {
    display: block !important;
  }

  .dark-theme.sidenav-toggled .desktop-logo.logo-dark .main-logo.dark-theme {
    display: none !important;
  }
}
@media (max-width: 578px) {
  .dark-theme .desktop-logo.logo-light .main-logo {
    display: none;
  }
  .dark-theme .logo-icon.mobile-logo.icon-light {
    display: none !important;
  }
  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon.dark-theme {
    display: block !important;
  }
  .dark-theme .logo-icon.mobile-logo.icon-dark {
    display: block !important;
  }
}
.dark-theme.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark .main-logo.dark-theme {
  display: block !important;
}

.dark-theme.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo.logo-dark {
  display: block !important;
}

.datetimepicker table td.new {
  color: #7a82af;
}

.dark-theme .datetimepicker table td {
  background: #1f2940;
  color: #fff;
}
.dark-theme .datetimepicker table th.prev {
  background-color: #1f2940;
  color: #fff;
}

.dark-theme .datetimepicker table th.next, .dark-theme .datetimepicker table th.switch {
  background-color: #1f2940;
  color: #fff;
}

.dark-theme .datetimepicker table th.prev:hover, .datetimepicker table th.prev:focus {
  background-color: #1f2940;
}

.dark-theme .datetimepicker table th.next:hover, .dark-theme .datetimepicker table th.next:focus {
  background-color: #1f2940;
}
.dark-theme .datetimepicker table th.prev span::before, .dark-theme .datetimepicker table th.next span::before {
  color: #fff;
}
.dark-theme .datetimepicker table th.switch:hover {
  background-color: #1f2940;
  color: #5965f9;
}

.datetimepicker table th.switch:focus {
  background-color: #1f2940;
  color: #5965f9;
}

.dark-theme .main-datetimepicker > .datepicker_inner_container > .datepicker_calendar td.hover {
  background-color: #0162e8;
}
.dark-theme .iti__selected-flag {
  border-right: 1px solid rgba(225, 230, 241, 0.1);
}
.dark-theme .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #25304a;
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  background: #1f2940;
}
.dark-theme .dashboard-carousel .btn-icons {
  background: rgba(239, 242, 246, 0.2) !important;
  color: #fff;
}
.dark-theme .slide.dashboard-carousel:hover {
  background: transparent;
}
.dark-theme .btn-light:focus, .dark-theme .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
}
.dark-theme .btn-light:not(:disabled):not(.disabled):active, .dark-theme .btn-light:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: rgba(226, 232, 245, 0.1);
  border-color: rgba(189, 198, 214, 0.2);
}
.dark-theme .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: rgba(226, 232, 245, 0.1);
  border-color: rgba(189, 198, 214, 0.2);
}
.dark-theme .modal-dialog {
  box-shadow: none;
}
.dark-theme .email-media .media-body small {
  color: white;
}
.dark-theme .email-media .media-body .media-title {
  color: white;
  font-size: 15px;
}
.dark-theme .page-item.disabled .page-link {
  color: #fff;
  background: #141b2d;
}
.dark-theme .demo-gallery .pagination {
  border: 0;
}
.dark-theme .chat .msg_cotainer, .dark-theme .chat .msg_cotainer_send {
  background-color: #2d3b58;
}
.dark-theme .chat .dropdown-menu li {
  padding: 7px;
  color: #dde2e8;
}
.dark-theme .chat .dropdown-menu li:hover {
  color: #0162e8;
  background: rgba(238, 238, 247, 0.06);
}
.dark-theme #basic .breadcrumb {
  background-color: #27334e;
}
.dark-theme .latest-tasks .tasks .span {
  color: #fff;
}
.dark-theme .list-group-item-action {
  color: #7987a1;
}
.dark-theme .list-group-item.active {
  color: #fff;
}
.dark-theme .list-group-item-success {
  color: #1f5c01;
  background-color: #c8e9b8;
}
.dark-theme .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.dark-theme .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.dark-theme .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.dark-theme .bg-danger {
  background-color: #ee335e !important;
}
.dark-theme .bg-primary {
  background-color: #0162e8 !important;
}
.dark-theme .bg-secondary {
  background-color: #7987a1 !important;
}
.dark-theme .bg-gray-800 {
  background-color: #3b4863 !important;
}
.dark-theme .bg-success {
  background-color: #22c03c !important;
}
.dark-theme .bg-info {
  background-color: #17a2b8 !important;
}
.dark-theme .bg-info p.card-text {
  color: #fff;
}
.dark-theme .main-content-body.main-content-body-contacts .media-body h5 {
  color: #fff;
}
.dark-theme .table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #25304a;
}
.dark-theme .dataTables_wrapper .dataTables_filter input {
  color: #fff;
}
.dark-theme .dataTables_wrapper .dataTables_filter input::-moz-placeholder {
  color: #97a3b9;
}
.dark-theme .dataTables_wrapper .dataTables_filter input:-ms-input-placeholder {
  color: #97a3b9;
}
.dark-theme .dataTables_wrapper .dataTables_filter input::placeholder {
  color: #97a3b9;
}
.dark-theme .dataTables_paginate .page-link {
  background: transparent;
}
.dark-theme .iti__divider {
  border-bottom: 1px solid rgba(189, 198, 214, 0.2);
}
.dark-theme .iti__country.iti__highlight {
  background-color: #1f2940;
}
.dark-theme .iti__country-list {
  background-color: #1f2940;
  box-shadow: 0 8px 16px 0 #2c3252;
}
.dark-theme .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: #414565;
}
.dark-theme .price.panel-color > .panel-body {
  background-color: #1f2940;
  border: 1px solid rgba(234, 237, 241, 0.1);
  border-bottom: 0;
  border-top: 0;
}
.dark-theme table.dataTable tbody td.sorting_1 {
  background: #25304a;
}
.dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  background-color: transparent;
  color: #fff;
}
.dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
  background-color: transparent;
  color: #fff;
}
.dark-theme .main-nav-line .nav-link.active {
  color: #fff;
}
.dark-theme .datetimepicker table {
  background: transparent;
}

.dark-theme .dataTables_paginate .pagination .page-link {
  border: 1px solid rgba(205, 215, 239, 0.15);
}

@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background-color: #1f2940;
    border: none;
  }
}
@media only screen and (min-width: 992px) {
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #0162e8;
    background: transparent;
  }
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background: #1f2940;
    box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
  }
}
@media (min-width: 768px) {
  .dark-theme .main-content-left-contacts {
    border-right: 1px solid rgba(226, 232, 245, 0.1);
  }
}
@media (min-width: 576px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar .fc-left button {
    color: #fff;
  }
  .dark-theme .main-calendar .fc-header-toolbar .fc-left button.fc-today-button {
    color: #000;
  }
}
@media (max-width: 605px) {
  .dark-theme .fc-view, .fc-view > table {
    border: 1px solid rgba(226, 232, 245, 0.2);
  }
}
.dark-theme .chat abbr.timestamp, .dark-theme .chat .msg_time, .dark-theme .chat .msg_time_send {
  color: #fff;
}
.dark-theme .nav-tabs {
  border-bottom: 1px solid rgba(226, 232, 245, 0.2);
}
.dark-theme .nav-tabs .nav-link.active {
  border-color: rgba(226, 232, 245, 0.2);
}

.dark-theme .page-link {
  border: 1px solid rgba(226, 232, 245, 0.2);
}
.dark-theme .popover-head-primary .popover-header {
  color: #fff !important;
  background-color: #0162e8 !important;
}
.dark-theme .popover-head-secondary .popover-header {
  color: #fff !important;
  background-color: #0162e8 !important;
}
.dark-theme .popover-head-secondary.bs-popover-bottom .arrow::after, .dark-theme .popover-head-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #0162e8 !important;
}
.dark-theme .popover-primary .popover-header, .dark-theme .popover-secondary .popover-header {
  background-color: transparent !important;
  color: #fff !important;
}
.dark-theme .popover-primary.bs-popover-top .arrow::after, .dark-theme .popover-primary.bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #0162e8 !important;
}
.dark-theme .popover-secondary.bs-popover-bottom .arrow::after, .dark-theme .popover-secondary.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #0162e8 !important;
}

.dark-theme .gray-set .bg-transparent {
  background-color: transparent !important;
}
.dark-theme .gray-set .bg-gray-100 {
  background-color: #ecf0fa !important;
}
.dark-theme .gray-set .bg-gray-200 {
  background-color: #dde2ef !important;
}
.dark-theme .gray-set .bg-gray-300 {
  background-color: #d0d7e8 !important;
}
.dark-theme .gray-set .bg-gray-400 {
  background-color: #b9c2d8 !important;
}
.dark-theme .gray-set .bg-gray-500 {
  background-color: #949eb7 !important;
}
.dark-theme .gray-set .bg-gray-600 {
  background-color: #737f9e !important;
}
.dark-theme .gray-set .bg-gray-700 {
  background-color: #4d5875 !important;
}
.dark-theme .gray-set .bg-gray-800 {
  background-color: #364261 !important;
}
.dark-theme .gray-set .bg-gray-900 {
  background-color: #242f48 !important;
}

.dark-theme .main-card-signin {
  box-shadow: 0 1px 15px 1px #191f3a;
}

.dark-theme .page-item.disabled .page-link {
  border-color: #4c576f;
  background: #27334e;
}

.dark-theme .ps > .ps__rail-y {
  background-color: #1f2940;
}

.dark-theme .app-sidebar .ps > .ps__rail-y {
  background-color: transparent;
}

.dark-theme .slide.is-expanded .side-menu__icon, .dark-theme .slide.is-expanded .angle {
  color: #0162e8 !important;
}

.dark-theme .close-toggle {
  color: #fff !important;
}

.dark-theme .slide.is-expanded .side-menu__label, .dark-theme .slide.is-expanded .side-menu__icon, .dark-theme .slide.is-expanded .angle {
  color: #fff;
}

.dark-theme .slide-item.active, .dark-theme .slide-item:hover, .dark-theme .slide-item:focus {
  color: #2a7ef3 !important;
}

.dark-theme .side-menu .slide.active .side-menu__label, .dark-theme .side-menu .slide.active .side-menu__icon {
  color: #2a7ef3 !important;
}

.dark-theme .slide .side-menu__label {
  color: #a0aac3 !important;
}

.dark-theme .side-menu__item.active .side-menu__label, .side-menu__item.active .side-menu__label .side-menu__label {
  color: #2a7ef3 !important;
}

.dark-theme .card.bg-primary-gradient {
  background-image: linear-gradient(to left, #0db2de 0%, #005bea 100%) !important;
}

.dark-theme .card.bg-danger-gradient {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}

.dark-theme .card.bg-success-gradient {
  background-image: linear-gradient(to left, #48d6a8 0%, #029666 100%) !important;
}

.dark-theme .card.bg-warning-gradient {
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}

.dark-theme .vmap-wrapper {
  background: #1f2940 !important;
}

.dark-theme .card-dashboard-eight .list-group-item span {
  color: #f1f4f9;
}

.dark-theme .card-dashboard-eight .list-group-item {
  background-color: #1f2940;
  border-bottom: 1px solid rgba(231, 235, 243, 0.1);
  border-color: rgba(226, 232, 245, 0.1);
}

.dark-theme .sales-info h3, .dark-theme .card-table h4 {
  color: #f0f3f9;
}

.dark-theme .total-revenue h4 {
  color: #fcfdff;
}

.dark-theme .product-timeline ul.timeline-1:before {
  border-left: 2px dotted rgba(192, 204, 218, 0.3);
}

.dark-theme .main-dashboard-header-right > div h5 {
  color: #fff;
}

.dark-theme .customers .list-group-item-action:hover, .dark-theme .customers .list-group-item-action:focus {
  color: #0160e4;
  background-color: #2d364c;
}

.dark-theme .customers h5 {
  color: #fff;
}

.dark-theme .list-group-item {
  border-left: 0;
  border-right: 0;
}

.dark-theme .slide.is-expanded .side-menu__item {
  background: transparent;
}

.dark-theme nav.prod-cat li a {
  color: #b0bbcc;
}

.dark-theme .product-sale .wishlist {
  color: #000;
}

.dark-theme .nav-tabs.preview-thumbnail {
  border-bottom: 0;
}

.dark-theme .shopping-cart-footer {
  border-top: 1px solid rgba(225, 231, 236, 0.1);
}

.dark-theme .select2-dropdown {
  border-color: rgba(208, 215, 232, 0.1);
}

.dark-theme .bd-2 {
  border-width: 2px !important;
}

.dark-theme .sidebar-right .list a {
  color: rgba(247, 248, 251, 0.7);
}

.dark-theme .card-footer {
  background-color: #1f2940;
}

.dark-theme .card.card-primary {
  border-top: 2px solid #0162e8 !important;
}

.dark-theme .card.card-secondary {
  border-top: 2px solid #737f9e !important;
}

.dark-theme .card.card-success {
  border-top: 2px solid #22e840 !important;
}

.dark-theme .card.card-danger {
  border-top: 2px solid #ee335e !important;
}

.dark-theme .card.card-warning {
  border-top: 2px solid #ffb209 !important;
}

.dark-theme .card.card-info {
  border-top: 2px solid #01b8ff !important;
}

.dark-theme .card.card-purple {
  border-top: 2px solid #673ab7 !important;
}

.dark-theme .card.card-dark {
  border-top: 2px solid #343a40 !important;
}

.dark-theme .nav-tabs.html-source {
  border-bottom: 0;
}

.dark-theme .nav-tabs.html-source .nav-link.active {
  border-color: #141b2d;
  background-color: #141b2d;
}

.dark-theme .toast-body {
  padding: 0.75rem;
  background: #212c46;
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link {
  background: #1f2940;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
  background: #1f2940 !important;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link.active {
  background: #1f2940 !important;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
  border: 1px solid rgba(226, 232, 245, 0.1);
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
  background: #171f33;
  color: #fff;
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a {
  background: #141b2d;
  color: #fff;
}

.dark-theme .nav-link.html-code {
  background: #1f2940;
}

.dark-theme .nav-tabs .nav-link.html-code.active, .dark-theme .nav-tabs .nav-link.html-code:hover, .dark-theme .nav-tabs .nav-link.html-code:focus {
  background-color: #141b2d;
  color: #fff;
}

.dark-theme .nav-tabs .nav-link.html-code:hover, .dark-theme .nav-tabs .nav-link.html-code:focus {
  border: 0;
}

.dark-theme .card .box {
  box-shadow: 0 0 25px #1d273d;
  border: 0;
}

.dark-theme .userlist-table .user-link {
  color: #fbf5f5;
}

.dark-theme .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #0162e8;
}

.dark-theme .main-chat-body .content-inner:before {
  background: rgba(15, 26, 51, 0.93);
}

.dark-theme .left.main-msg-wrapper:before {
  color: #343e53;
}

.dark-theme .icons-list-item {
  border: 1px solid rgba(214, 220, 236, 0.15);
}

.dark-theme .user-wideget-footer {
  background-color: #1f2940;
}

.dark-theme .profile.navtab-custom .active a {
  background: #273350;
  border-bottom: 0;
  color: #fffafa;
}

.dark-theme .profile.navtab-custom li a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(227, 230, 240, 0.1);
}

.dark-theme .profile.navtab-custom a.active {
  background: #273350;
}

.dark-theme .nav.prod-cat li a {
  color: #fff;
}

.dark-theme .prod-cat li ul li.active a {
  background: none;
  color: #ee335e;
}

.dark-theme .header-icon-svgs {
  color: #dde3ea;
}

.dark-theme .app-sidebar .side-item.side-item-category {
  color: #dde3ea;
}

.dark-theme .product-pagination .page-link {
  background-color: #1f2940 !important;
  border: 1px solid #2e3c5d;
}

.dark-theme .product-pagination .page-item.disabled .page-link {
  border-color: #3c465d;
  background: #1f283c;
  color: #555d6f;
}

.dark-theme .price span {
  color: #ebedf1;
}

.dark-theme #list3 .media-body h6 {
  color: white;
}

.dark-theme #list3 .list-group-item, .dark-theme #list8 .list-group-item, .dark-theme #list1 .list-group-item, .dark-theme #list8 .list-group-item {
  border-left: 1px solid rgba(231, 235, 243, 0.1);
  border-right: 1px solid rgba(231, 235, 243, 0.1);
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs {
  border-bottom: 1px solid #2d364b;
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs .nav-link {
  background-color: #141b2d;
}

.dark-theme .popover-static-demo .popover {
  box-shadow: -8px 12px 18px 0 #081127;
  border: 1px solid #3a4869;
}

.dark-theme .heading-inverse {
  background-color: #141b2c;
}

.dark-theme .toast {
  box-shadow: -8px 12px 18px 0 #141b2d;
}

.dark-theme .tabs-style-1 .dark-theme .border {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .tabs-style-1 .dark-theme .border-top-0 {
  border-top: 0 !important;
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link.active {
  color: #f3f5f9;
  border: 1px solid;
  border-color: rgba(224, 230, 237, 0.1) rgba(224, 230, 237, 0.1) #1f2940;
}

.dark-theme .tabs-style-1 .panel-tabs {
  border-bottom: 0;
}

.dataTables_paginate .page-item.previous a {
  width: 77px;
}

.dark-theme.app.sidenav-toggled.sidenav-toggled-open .side-menu__label {
  color: #a0aac3;
}

.dark-theme.app.sidenav-toggled.sidenav-toggled-open .side-menu__item.active .side-menu__label {
  color: #a0aac3;
}

@media (min-width: 768px) {
  .dark-theme.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .desktop-logo {
    display: none !important;
  }

  .dark-theme.leftbgimage1 .main-sidebar-header, .dark-theme.leftbgimage2 .main-sidebar-header, .dark-theme.leftbgimage3 .main-sidebar-header, .dark-theme.leftbgimage4 .main-sidebar-header, .dark-theme.leftbgimage5 .main-sidebar-header {
    border-right: 1px solid #242e45;
    width: 240px;
  }
}
@media (max-width: 1199px) {
  .dark-theme .total-revenue {
    border-bottom: 0px solid rgba(227, 232, 247, 0.1) !important;
  }
}
.dark-theme .horizontalMenucontainer .side-menu__icon {
  color: #bfc8de;
  fill: #bfc8de;
}

.dark-theme.horizontal-light .horizontalMenucontainer .side-menu__icon {
  color: #5b6e88;
  fill: #5b6e88;
}

.dark-theme .main-header .dropdown-menu-left {
  box-shadow: 0px 0px 15px 1px #041138;
}

.dark-theme .main-header .dropdown.nav-itemd-none .dropdown-menu:after {
  border-bottom: 9px solid #1f2940;
}

@media (min-width: 992px) {
  .dark-theme .top-header .header-brand.header-brand2 .desktop-dark {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
}
.dark-theme .app-sidebar.toggle-sidemenu {
  border-top: 1px solid rgba(222, 228, 236, 0.1);
}

.dark-theme .first-sidemenu {
  background: #1f2940;
}

.dark-theme .second-sidemenu {
  background: #1f2940;
  border-right: 1px solid rgba(222, 228, 236, 0.1);
}

.dark-theme .resp-vtabs .resp-tabs-list li {
  border: 1px solid rgba(222, 228, 236, 0.1) !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0;
}

.dark-theme .first-sidemenu li.active, .dark-theme .resp-vtabs .resp-tab-active:hover {
  background: #1f2940;
  border-right: 0 !important;
}

.dark-theme .resp-vtabs li.resp-tab-active {
  border-right: 0 !important;
}

.dark-theme .first-sidemenu .side-menu__icon, .dark-theme .toggle-sidemenu .slide-item {
  color: #a0aac3;
  fill: #a0aac3;
}

.dark-theme .second-sidemenu h5 {
  color: #dde3ea;
}

.dark-theme.app.sidebar-mini.sidenav-toggled .first-sidemenu li.active {
  border-right: 1px solid rgba(222, 228, 236, 0.1) !important;
}

.dark-theme .file-browser .btn-default {
  background: #2c3752;
  color: #fff;
  border: 1px solid rgba(226, 232, 245, 0.1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dark-theme .card.bg-purple-gradient {
  background-image: linear-gradient(to right, #673ab7 0%, #884af1 100%) !important;
}

.dark-theme .fc-datepicker.main-datepicker.hasDatepicker {
  border: 1px solid #333d52;
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label, .dark-theme .ql-snow .ql-picker:focus .ql-picker-label {
  color: #fff;
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label, .dark-theme .ql-snow .ql-picker:focus .ql-picker-label {
  color: #fff;
}

.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #2c3242;
}

.dark-theme.hoversubmenu .side-menu__item.active .side-menu__icon {
  fill: #106ef1;
}

@media (min-width: 768px) {
  .dark-theme.hoversubmenu.style1-leftmenu.sidenav-toggled .app-sidebar .side-menu-label1 {
    border-bottom: 1px solid rgba(225, 230, 241, 0.1);
    color: #fff !important;
  }
}
@media (min-width: 768px) {
  .dark-theme.app.sidebar-mini.sidenav-toggled-open .ps > .ps__rail-y > .ps__thumb-y {
    background-color: #1f2940;
  }
}
@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list {
    border-right: 1px solid #343d52;
  }
}
@media (min-width: 768px) {
  .dark-theme .main-nav.main-nav-colored-bg .nav-link + .nav-link {
    border-left: 1px solid #fff;
  }

  .dark-theme .main-nav .nav-link + .nav-link {
    border-left: 1px solid #3c475f;
  }
}
.dark-theme .main-mail-compose-body {
  background-color: #141b2d;
}
.dark-theme .main-mail-compose-body .form-group + .form-group {
  border-top: 1px dotted #333d51;
}
.dark-theme .main-mail-compose-body .form-group .form-control {
  background-color: #232e48 !important;
}
.dark-theme .main-mail-compose-body .form-group .form-label {
  color: #cfdaec;
}

.dark-theme .main-mail-compose-body .form-group .nav-link {
  color: #cfdaec;
}

@media (max-width: 767px) {
  .dark-theme .main-signup-header, .dark-theme .main-card-signin {
    border: 1px solid rgba(231, 235, 243, 0.1);
  }
}
.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
  background-color: #1f2940;
  box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
  border: none;
}

.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
    box-shadow: none !important;
  }
}
.dark-theme .sales-bar line {
  stroke: rgba(192, 204, 218, 0.1);
}

.dark-theme .sales-bar text {
  fill: rgba(255, 255, 255, 0.3);
}

.dark-theme #chart text {
  fill: rgba(255, 255, 255, 0.3);
}

.dark-theme .error-page1 .bg-primary-transparent {
  background: rgba(40, 92, 247, 0.2) !important;
}
.dark-theme .error-bg .page {
  background: rgba(40, 92, 247, 0.2) !important;
}
.dark-theme .error-page1, .dark-theme .error-bg {
  background: #212b42 !important;
}
.dark-theme .error-page1 .bg-white, .dark-theme .underconstruction-page .bg-white {
  background: #141b2d !important;
}
.dark-theme .error-page1 .text-dark {
  color: #fff !important;
}/*# sourceMappingURL=style-dark.css.map */